<template>
  <div>
    <div class="row m-0" :class="{darkbg: background === 'dark'}">
      <div class="col-12 pl-lg-0 pr-lg-2 p-0">
        <label>Do you wat to upload a copy of your passport?</label>
        <input type="radio" id="wantYes" :value="true" v-model="want" class="ml-3">
        <label for="wantYes" class="pl-2">Yes</label>
        <input type="radio" id="wantNo" :value="false" v-model="want" class="ml-3">
        <label for="wantNo" class="pl-2">No</label>
      </div>
      <div class="col-12 pl-lg-0 pr-lg-2 p-0" v-if="want">
        <file-uploader :uploadFieldName="uploadFieldName" :acceptArr="['pdf', 'image']" :multiple="false" :currentStatus="currentStatus" @filesChange="filesChange" ref="uploadPassportComponent"></file-uploader>
        <button type="button" class="btn w-100" @click.stop="save()" :disabled="!buttonEnabled" v-if="!isInitial || hasFiles">
          <span v-if="isSaving">Uploading {{hasFiles}}...</span>
          <span v-else-if="isSuccess">File Successfully Uploaded</span>
          <span v-else-if="isFailed">{{uploadError}}. Please click here to try again</span>
          <span v-else>Upload</span>
        </button>
      </div>
    </div>
    <!-- <div class="col-md-12 p-0" v-if="changed && !saved && passwordOK">
      <button type="button" class="btn w-100" @click="save()" :disabled="saving || saved">
        <span v-if="saving">Saving...</span>
        <span v-else-if="saved">Saved</span>
        <span v-else>Save</span>
      </button>
    </div> -->
    <!-- <div class="col p-0" v-if="saved">
      Password succesfully updated!
    </div> -->
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import { STATUS_INITIAL, STATUS_SAVING, STATUS_SUCCESS, STATUS_FAILED } from '@/components/FileUploader.vue'
// const STATUS_INITIAL = 0; const STATUS_SAVING = 1; const STATUS_SUCCESS = 2; const STATUS_FAILED = 3
export default {
  name: 'PassportDetails',
  props: ['background'],
  components: {
    'file-uploader': () => import('@/components/FileUploader.vue')
  },
  data: () => ({
    want: null,
    uploadFieldName: 'files',
    currentStatus: STATUS_INITIAL,
    formData: null,
    hasFiles: false,
    uploadError: null
  }),
  computed: {
    ...mapGetters([
      'user'
    ]),
    buttonEnabled () {
      return (this.isInitial && this.hasFiles) || this.isFailed
    },
    isInitial () {
      return this.currentStatus === STATUS_INITIAL
    },
    isSaving () {
      return this.currentStatus === STATUS_SAVING
    },
    isSuccess () {
      return this.currentStatus === STATUS_SUCCESS
    },
    isFailed () {
      return this.currentStatus === STATUS_FAILED
    }
  },
  methods: {
    ...mapActions([
      'uploadDocument'
    ]),
    backToFormInitialStatus (delay = 8000) {
      setTimeout(() => {
        this.setStatusToInitial()
      }, delay)
    },
    save () {
      if (this.isFailed) {
        this.setStatusToInitial()
      } else {
        this.currentStatus = STATUS_SAVING
        this.uploadDocument(this.formData)
          .then(x => {
            this.uploadedFiles = [].concat(x)
            this.currentStatus = STATUS_SUCCESS
            this.backToFormInitialStatus()
          })
          .catch(err => {
            this.uploadError = err.message || 'OOPS! Something went wrong...'
            this.currentStatus = STATUS_FAILED
          })
          .finally(a => {
            this.hasFiles = false
          })
      }
    },
    filesChange (formData) {
      // handle file changes
      this.formData = formData
      this.formData.append('userId', this.user.id)
      this.formData.append('type', 'passport')
      this.hasFiles = this.formData && this.formData.get(this.uploadFieldName) ? this.formData.get(this.uploadFieldName).name : false
    },
    setStatusToInitial () {
      this.currentStatus = STATUS_INITIAL
    }

    // fileChanged (f) {
    //   const fileList = this.$refs.fileInput.files
    //   if (fileList) {
    //     for (const f of Object.values(fileList)) {
    //       if (this.totalSizeCustomAttachments + f.size > 10000000) {
    //         alert('Max total size for attachments is 10Mb')
    //         break
    //       // } else if (f.size > 2000000) {
    //       //   alert('File "' + f.name + '" is too big. Max size 10Mb')
    //       } else {
    //         this.customAttachments.push(f)
    //       }
    //     }
    //   }
    // },
    // save () {
    //   this.saving = true
    //   this.errorMsg = null
    //   this.updatePassword(this.details).then(data => {
    //     this.saving = false
    //     if (data.success) {
    //       this.saved = true
    //     } else {
    //       this.errorMsg = data.message
    //     }
    //   },
    //   err => {
    //     console.error(err)
    //     this.saving = false
    //     this.saved = false
    //     this.errorMsg = err
    //   })
    // }
  }
}
</script>
<style scoped>
.underline {
  text-decoration: underline;
}
.smallicon {
  width: 23px;
  height: 23px;
}
.addicon {
  width: 35px;
  height: 35px;
}
/* Customize the label (the container) */
.container {
  display: block;
  position: relative;
  padding-right: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  right: 0;
  height: 24px;
  width: 24px;
  background-color: #58595B;
}
.darkbg .checkmark {
  height: 13px;
  width: 13px;
  bottom: 2px;
  top: inherit;
  background-color: #fff;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: var(--primarycolor);
}
/* When the checkbox is checked, add a blue background */
.darkbg .container input:checked ~ .checkmark {
  background-color: #fff;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
  left: 10px;
  top: 6px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.darkbg .container .checkmark:after {
  left: 4px;
  top: 2px;
  width: 5px;
  height: 8px;
  border: solid var(--primarycolor);
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
</style>
