<template>
  <div class="col p-0">

    <form enctype="multipart/form-data" novalidate v-if="isInitial || isSaving" class="pt-3">
      <div class="dropbox">
        <input type="file" :name="uploadFieldName" :multiple="multiple" :disabled="isSaving" @change="filesChange($event.target.name, $event.target.files)"
          :accept="accept" class="input-file">
          <p v-if="isInitial">
            Drag your file<span v-if="multiple">(s)</span> here to begin<br> or click to browse
          </p>
          <p v-if="isSaving">
            Uploading {{filenamesString}}...
          </p>
      </div>
    </form>
  </div>
</template>
<script>
export const STATUS_INITIAL = 0
export const STATUS_SAVING = 1
export const STATUS_SUCCESS = 2
export const STATUS_FAILED = 3

const appendToAccept = (accept, value) => {
  return accept.length === 0 ? value : accept + ', ' + value
}
export default {
  name: 'FileUploader',
  props: {
    acceptArr: {
      type: Array,
      required: false
    },
    currentStatus: {
      type: Number,
      required: true
    },
    // isSaving: {
    //   type: Boolean,
    //   required: true,
    //   default: () => false
    // },
    multiple: {
      type: Boolean,
      required: false,
      default: () => false
    },
    uploadFieldName: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      accept: '',
      filenames: [],
      uploadedFiles: []
    }
  },
  computed: {
    filenamesString () {
      return this.filenames && this.filenames.length > 0 ? this.filenames.join(', ') : ''
    },
    isInitial () {
      return this.currentStatus === STATUS_INITIAL
    },
    isSaving () {
      return this.currentStatus === STATUS_SAVING
    },
    isSuccess () {
      return this.currentStatus === STATUS_SUCCESS
    },
    isFailed () {
      return this.currentStatus === STATUS_FAILED
    }
  },
  watch: {
    currentStatus: {
      handler (newVal) {
        if (newVal === STATUS_INITIAL) {
          this.reset()
        }
      },
      immediate: true
    }
  },
  mounted () {
    if (this.acceptArr && Array.isArray(this.acceptArr) && this.acceptArr.length > 0) {
      for (const filetype of this.acceptArr) {
        switch (filetype) {
          case 'doc':
            this.accept = appendToAccept(this.accept, '.doc, .docx, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document')
            break
          case 'image':
            this.accept = appendToAccept(this.accept, 'image/*')
            break
          case 'pdf':
            this.accept = appendToAccept(this.accept, '.pdf, application/pdf')
            break
        }
      }
    } else {
      this.accept = '*'
    }
  },
  methods: {
    reset () {
      // reset form to initial state
      this.uploadedFiles = []
    },
    filesChange (fieldName, fileList) {
      // handle file changes
      const formData = new FormData()

      if (!fileList.length) return
      this.filenames = []

      // append the files to FormData
      Array
        .from(Array(fileList.length).keys())
        .map(x => {
          this.filenames.push(fileList[x].name)
          formData.append(fieldName, fileList[x], fileList[x].name)
        })

      // save it
      this.$emit('filesChange', formData)
    }
  }
}
</script>
<style scoped>
  .dropbox {
    outline: 2px dashed grey; /* the dash box */
    outline-offset: -10px;
    background: #5D5F61;
    color: #D1D3D4;
    padding: 10px 10px;
    min-height: 200px; /* minimum height */
    position: relative;
    cursor: pointer;
  }

  .input-file {
    opacity: 0; /* invisible but it's there! */
    width: 100%;
    height: 200px;
    position: absolute;
    cursor: pointer;
  }

  .dropbox:hover {
    background: #374D50; /* when mouse over to the drop zone, change color */
    color: #D1D3D4;
  }

  .dropbox p {
    font-size: 0.8em;
    text-align: center;
    padding: 50px 0;
  }
</style>
